import styled from 'styled-components';

export const SearchBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.core.sizing[3]}px;

  background: ${({ theme }) => theme.core.colors.greyscale.white};
  width: 100%;
  max-width: 600px;

  border-radius: 2px;
  padding: ${({ theme }) => theme.core.sizing[3]}px;

  margin: auto 0;

  > h2 {
    color: ${({ theme }) => theme.core.colors.greyscale.black};
    font-family: ${({ theme }) => theme.core.fontFamily.jost};

    font-size: ${({ theme }) => theme.core.fontSizes.xxxl}px;

    margin: 0;
  }
  > p {
    color: ${({ theme }) => theme.core.colors.greyscale.black};

    margin: 0;
  }

    > button {
    justify-self: flex-end;
    align-self: flex-end;
    }


  input {
    height: 45px;
  }

  @media ${({ theme }) => `${theme.core.breakpoints.m}`} {
    width: 500px;

    button,
    input {
      height: 36px;
    }

    > button {
      width: 150px;
    }
  }
`;