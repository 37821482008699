import { useQuery } from "react-query";
import { useAuthContext } from "@pata-app/ui-auth";
import { endpoints, request } from "../lib/api";

export function useGetUserDetails() {
  const { userId } = useAuthContext();
  return useQuery({
    queryKey: ["get-user-details", userId],
    queryFn: () => request({ endpoint: endpoints.user(userId!), method: 'GET' }),
    enabled: !!userId,
  });
}
