import styled from 'styled-components';

export const SearchInputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.core.sizing[1]}px;
`;

export const SearchInputWrapper = styled.div`
  position: relative;

  z-index: 2;

  &:last-of-type {
    z-index: 1;
  }

  > svg {
    position: absolute;
    top: 12px;
    left: 12px;
  }

  input {
    padding-left: 40px;
    width: 100%;

    background: ${({ theme }) => theme.core.colors.greyscale.light};

    border: none;
  }

  @media ${({ theme }) => `${theme.core.breakpoints.s}`} {
    > svg {
      top: 8px;
      left: 12px;
    }
  }
`;

interface ICurrentLocation {
  isFetchingLocation: boolean;
}

export const CurrentLocation = styled.button<ICurrentLocation>`
  position: absolute;
  right: 0;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;

  background: transparent;
  border: none;

  cursor: pointer;

  svg {
    margin-right: ${({ isFetchingLocation }) => (isFetchingLocation ? '-12px' : '2px')};
  }
`;
