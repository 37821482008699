import styled, { css } from 'styled-components';

interface IPrediction {
  isSelected: boolean;
}

const ButtonStyled = css`
  flex-shrink: 0;

  width: 100%;
  height: 32px;

  padding-left: 8px;

  background: none;

  border: none;

  text-align: left;

  font-size: ${({ theme }) => theme.core.fontSizes.s}px;

  color: ${({ theme }) => theme.core.colors.greyscale.dark};

  cursor: pointer;

  :hover {
    background: ${({ theme }) => theme.core.colors.greyscale.light};
  }
`;

export const InputStyled = css`
  height: 36px;
  width: 100%;

  border: none;

  color: ${({ theme }) => theme.core.colors.greyscale.dark};

  font-family: ${({ theme }) => theme.core.fontFamily.jost};
  font-weight: 600;

  text-transform: capitalize;
`;

export const AutoCompleteInput = styled.input`
  height: 36px;
  width: 100%;

  border: none;
  border-radius: 2px;

  color: ${({ theme }) => theme.core.colors.greyscale.dark};

  font-family: ${({ theme }) => theme.core.fontFamily.jost};
  font-weight: 600;

  text-transform: capitalize;
`;

export const PredictionsContainer = styled.div`
  display: flex;
  flex-direction: column;

  position: absolute;
  width: 100%;
  max-height: 300px;
  top: 37px;

  overflow: auto;

  background: ${({ theme }) => theme.core.colors.greyscale.white};

  border: 1px solid ${({ theme }) => theme.core.colors.greyscale.medium};

  box-shadow: rgb(0 0 0 / 28%) 0px 8px 28px;

  border-radius: ${({ theme }) => theme.core.border.container.radius}px;
`;

export const PredictionGroupTitle = styled.strong`
  height: 32px;
  line-height: 32px;

  padding-left: 8px;

  border-top: 1px solid ${({ theme }) => theme.core.colors.greyscale.medium};

  font-size: ${({ theme }) => theme.core.fontSizes.sm}px;
  color: ${({ theme }) => theme.core.colors.primary.blue.medium};

  text-transform: capitalize;
`;

export const Prediction = styled.button<IPrediction>`
  ${ButtonStyled}

  background: ${({ isSelected, theme }) => (isSelected ? theme.core.colors.primary.teal : 'initial')};
  color: ${({ isSelected, theme }) => (isSelected ? theme.core.colors.greyscale.white : 'initial')};

  &:hover {
    color: ${({ theme }) => theme.core.colors.greyscale.black};
  }

  span {
    text-transform: capitalize;
  }
`;

export const FreeTextSearch = styled.button`
  ${ButtonStyled}

  span:first-of-type {
    font-style: italic;
  }
  span:last-of-type {
    font-weight: ${({ theme }) => theme.core.fontWeight.semiBold};
  }
`;
