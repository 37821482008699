import React, { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

import AutoComplete from '../../../../../../../components/autoComplete/autoComplete';
import Icon from '../../../../../../../components/icon/icon';
import PlacesAutoComplete from '../../../../../../../components/placeAutoComplete/placesAutoComplete';
import useSearchTermPrediction from '../../../../../../../hooks/useSearchTermPredictions';
import { CurrentLocation, SearchInputsWrapper, SearchInputWrapper } from './searchInputs.styled';

interface ISearchInput {
  searchTerm: string;
  location: string;
  onChange: (field: string, value: any) => void;
}

const SearchInputs = ({ searchTerm, location, onChange }: ISearchInput) => {
  const theme = useTheme();

  const [isFetchingLocation, setIsFetchingLocation] = useState(false);

  const { predictions, onInputChange } = useSearchTermPrediction();

  useEffect(() => {
    onInputChange(searchTerm);
  }, []);

  const handleLocationChange = (lat: number, lng: number, name?: string, locationType?: any) => {
    onChange('location', { lat, lng, name, locationType });
  };

  const handleSelect = (option: any) => {
    onChange('searchTerm', { name: option.name, type: option.group });

    onInputChange(option.name);
  };

  const handleUseCurrentLocation = () => {
    setIsFetchingLocation(true);

    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };

    const success = (pos: any) => {
      const crd = pos.coords;

      handleLocationChange(crd.latitude, crd.longitude);
      setIsFetchingLocation(false);
    };

    const error = (err: any) => {
      console.warn(`ERROR(${err.code}): ${err.message}`);
      setIsFetchingLocation(false);
    };

    navigator.geolocation.getCurrentPosition(success, error, options);
  };

  return (
    <SearchInputsWrapper>
      <SearchInputWrapper>
        <AutoComplete
          placeholder="What are you looking for?"
          value={searchTerm}
          predictions={predictions}
          onSelect={handleSelect}
          onInputChange={onInputChange}
          allowFreeText
          groupKey="group"
          freeSearchDescription="Business name"
        />
        <Icon type="PawOutline" size="18px" fill={theme.core.colors.primary.blue.medium} />
      </SearchInputWrapper>

      <SearchInputWrapper>
        <PlacesAutoComplete placeValue={location !== 'null' ? location : ''} onPlaceSelected={handleLocationChange} />
        <Icon type="PinLight" size="18px" fill={theme.core.colors.primary.blue.medium} />
        <CurrentLocation
          isFetchingLocation={isFetchingLocation}
          onClick={handleUseCurrentLocation}
          aria-label="Use current location"
        >
          <Icon
            type={isFetchingLocation ? 'Loader' : 'CurrentLocation'}
            fill={theme.core.colors.primary.blue.medium}
            size={isFetchingLocation ? '40px' : '20px'}
          />
        </CurrentLocation>
      </SearchInputWrapper>
    </SearchInputsWrapper>
  );
};

export default SearchInputs;
