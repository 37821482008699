import React, { useState } from 'react';

import generateQueryString from '../../../../../lib/utils/generateQueryString';
import SearchInputs from './components/searchInputs/searchInputs';
import { SearchBoxContainer } from './searchBox.styled';
import { Button } from '@pata-app/ui-form';

const SearchBox = () => {
  const [searchState, setSearchState] = useState({
    searchTerm: null,
    searchTermType: null,
    locationType: null,
    location: null,
    lat: null,
    lng: null,
  });

  const handleInputChange = (field: string, value: any) => {
    if (field === 'searchTerm') {
      setSearchState({
        ...searchState,
        searchTerm: value.name,
        searchTermType: value.type,
      });
    }
    if (field === 'location') {
      setSearchState({
        ...searchState,
        location: value.name,
        locationType: value.locationType,
        lat: value.lat,
        lng: value.lng,
      });
    }
  };

  const handleSearch = () => {
    const formattedQueryStringObject = Object.keys(searchState).reduce(
      (updatedObject, key) => ({
        ...updatedObject,
        [key.toLowerCase()]: searchState[key as keyof typeof searchState],
      }),
      {},
    );

    const queryString = generateQueryString(formattedQueryStringObject);

    window.location.href = `${process.env.WEB_BASE_URL}/business/search${queryString}`;
  };

  return (
    <SearchBoxContainer>
      <h2>Let's Play Fetch!</h2>
      <p>Looking for a pet business near you? Find businesses that offer the services you want, right in your area.</p>
      <SearchInputs
        searchTerm={searchState.searchTerm}
        location={searchState.location || (searchState.lat && 'Current Location')}
        onChange={handleInputChange}
      />
      <Button buttonType="primary" onClick={handleSearch}>Fetch!</Button>
    </SearchBoxContainer>
  );
};

export default SearchBox;
