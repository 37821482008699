import { Footer } from '@pata-app/core';
import { Header } from '@pata-app/ui-document'
import { useLoginModalContext } from "@pata-app/ui-login-modal";
import React from 'react';

import { MainContainer } from './appWrapper.styled';
import { IAppWrapper } from './appWrapper.types';
import { useAuthContext } from '@pata-app/ui-auth';
import { useGetUserDetails } from '../../hooks/useGetUserDetails';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function getImageUrl(imagePath?: string | null) {
  if (!imagePath) return "";

  if (imagePath.includes("googleusercontent")) {
    return imagePath
  }

  return `${process.env.CDN_BASE_URL_USER_CONTENT}/${imagePath}`;
}

const AppWrapper = ({ children }: IAppWrapper) => {
  const { isAuthenticated, logout } = useAuthContext()
  const { setIsOpen } = useLoginModalContext()

  const handleLogout = () => {
    logout({ global: true })
    window.location.reload()
  }

  const { data } = useGetUserDetails()

  const user = data?.data?.data

  const userInfo = {
    firstName: user?.firstName,
    lastName: user?.lastName,
    imageUrl: getImageUrl(user?.profilePicture)
  }

  return (
    <>
      <Header domain='' isAuthenticated={isAuthenticated} userInfo={userInfo} onLogoutPress={handleLogout} onSignupPress={() => setIsOpen('register')} onLoginPress={() => setIsOpen('login')} />
      <MainContainer>{children}</MainContainer>
      <ToastContainer autoClose={5000}
        closeOnClick
        hideProgressBar
        newestOnTop={false}
        pauseOnFocusLoss
        pauseOnHover
        position="bottom-right"
        rtl={false}
        theme="dark" />
      <Footer baseUrl={process.env.WEB_BASE_URL} />
    </>
  );
};

export default AppWrapper;
