import React from 'react';
import styled from 'styled-components';

import ImageTile from '../../../../components/imageTile/imageTile';

interface IContactTile {
  imageUrl: string;
  title: string;
  description: string;
  emailLink: string;
}

const ContactTitleContainer = styled.a`
  flex: 1 0 calc(30% - 120px);

  text-decoration: none;

  color: ${({ theme }) => theme.core.colors.greyscale.black};

  strong {
    color: ${({ theme }) => theme.core.colors.primary.green.medium};
    font-weight: 400;
  }

  h3 {
    margin-bottom: 0;
    margin-top: ${({ theme }) => theme.core.sizing[4]}px;

    font-size: ${({ theme }) => theme.core.fontSizes.m}px;
  }

  p {
    margin-top: 0;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const ContactTile = ({ imageUrl, title, description, emailLink }: IContactTile) => {
  return (
    <ContactTitleContainer href={`mailto:${emailLink}`}>
      <ImageTile imageUrl={imageUrl} />
      <h3>{title}</h3>
      <p>{description}</p>
      <strong>{emailLink}</strong>
    </ContactTitleContainer>
  );
};

export default ContactTile;
