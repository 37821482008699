import i18n from 'i18next'
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  lng: 'en',
  resources: {
    en: {
      common: {
        cancel: "Cancel",
        back: "Back",
        login_with: "Login with",
        continue: "Continue",
        login: "Login",
        register: "Register"
      },
      register: {
        login_with_google: "Login with Google",
        sign_in_to_pata: "Sign in to Pata",
        sign_up_to_pata: "Sign up to Pata",
        user_not_found: "Could not find a user with that email address",
        register_modal_title_label: "Register for Pata",
        form_email: "Email",
        form_error_messages_email_required: "Email is required",        
        form_password: "Password",
        form_first_name: "First name",
        form_family_name: "Last name",
        form_confirm_password: "Confirm password",
        form_mobile_number: "Mobile number",
        form_error_messages_confirm_password_inavlid: "Passwords do not match",
        form_error_messages_email_invalid: "Not a valid email address",
        form_error_messages_password_required: "Password is required",
        form_error_messages_password_invalid: "Password 8+ chars, uppercase, lowercase, number, symbol.",
        form_error_messages_confirm_password_invalid: "Passwords don't match",
        form_error_messages_family_name_required: "Last name is required",
        form_error_messages_first_name_required: "First name is required",
        form_error_messages_first_name_invalid: "First name is not valid",
        form_error_messages_family_name_invalid: "Last name is not valid",
        form_error_messages_mobile_number_invalid: "Mobile number is not valid",
        form_error_messages_mobile_number_required: "Mobile number is required",
        form_error_messages_password_incorrect: "Incorrect username or password",
        form_error_messages_attempts_exceeded: "Login attempts exceeded"
      }
    }
  }
})

export default i18n
