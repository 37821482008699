import styled from 'styled-components';

export const CharityPartnerContainer = styled.section`
  > div {
    display: flex;
    flex-direction: column-reverse;
  }

  @media ${({ theme }) => `${theme.core.breakpoints.s}`} {
    height: 500px;

    > div {
      display: flex;
      flex-direction: row;
      align-items: center;

      height: 100%;

      * > {
        flex: 1 0 50%;
      }
    }
  }
`;

export const BusinessPartnerDescriptionBackground = styled.div`
  display: none;

  @media ${({ theme }) => `${theme.core.breakpoints.s}`} {
    display: flex;
    align-items: center;

    height: 80%;

    margin: auto;
    background: ${({ theme }) => theme.core.colors.greyscale.white};
  }
`;

export const BusinessPartnerDescription = styled.div`
  padding: 64px 32px 32px 32px;

  > h2 {
    display: flex;
    flex-direction: column;
    margin-top: 0;

    span {
      font-family: ${({ theme }) => theme.core.fontFamily.jost};
      font-size: ${({ theme }) => theme.core.fontSizes.xxxl}px;
    }
  }

  > p {
    line-height: 1.4;
  }

  button {
    font-weight: 700;
  }

  a {
    text-decoration: none;
  }

  @media ${({ theme }) => `${theme.core.breakpoints.s}`} {
    padding: 0;
  }
`;

export const BusinessPartnerImage = styled.div`
  padding: 32px 32px 64px 32px;

  img {
    width: 100%;
    object-fit: cover;
  }

  @media ${({ theme }) => `${theme.core.breakpoints.s}`} {
    display: flex;
    justify-content: center;
    flex: 1 0 50%;
  }

  @media ${({ theme }) => `${theme.core.breakpoints.l}`} {
    display: flex;
    justify-content: center;
    flex: 1 0 50%;

    padding: 0 90px 0 0;

    img {
      width: 80%;
    }
  }
`;
